<template>
  <van-popup
    v-model="isShow"
    class="confirmPopup"
    position="bottom"
    @click-overlay="clickCancel"
  >
    <section class="contentWrap">
      <div class="header">
        <img src="../../assets/images/area_icon.png" alt="">
        <span>
          {{ title }}
        </span>
      </div>
      <div class="content">
        <ul>
          <li
            v-for="item in data"
            :key="item.id"
            :class="{'active': activeItem === item}"
            @click="activeItem = item"
          >{{ item.name }}</li>
        </ul>
      </div>
    </section>
    <footer-bar
      has-cancel-btn
      @clickCancel="clickCancel"
      @clickConfirm="clickConfirm"
    />
  </van-popup>
</template>

<script>
// import { } from 'vuex'
import FooterBar from '../FooterBar/index';
export default {
  name: 'ConfirmPopup',
  components: { FooterBar },
  props: {
    isShow: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      activeItem: {}
    };
  },
  computed: {
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    clickConfirm() {
      this.$emit('clickConfirm', this.activeItem);
    },
    clickCancel() {
      this.$emit('clickCancel');
    }
  }

};
</script>

<style lang="scss" scoped>
.confirmPopup{

  .contentWrap{
    padding-bottom: 100px;
    .header{
      height: 138px;
      padding: 0 32px;
      font-size: 36px;
      display: flex;
      align-items: center;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
      border-radius: 12px 12px 0 0;
      img{
        width: 74px;
        height: 74px;
        margin-right: 16px;
      }
    }
    .content{
      height: 460px;
      overflow-y: auto;
      ul{
        li{
          height: 100px;
          line-height: 100px;
          border-bottom: 2px $color-bg solid;
          padding: 0 32px;
          &.active{
            background: $color-bg;
            color: $color-primary;
          }
        }
      }
    }
  }
}
</style>
